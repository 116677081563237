import styled, { Interpolation, css } from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

import { IconBadgePlacement } from '../../../types/graphql'

import { IconSize } from './IconBadge'

const iconBadgeLargeTileSideGap = '8px'
const iconBadgeLargeTileVertGap = '8px'

const iconBadgeSmallTileSideGap = '4px'
const iconBadgeSmallTileVertGap = '4px'

export const iconBadgeStyles: Record<
  IconBadgePlacement,
  Record<IconSize, Interpolation<object>>
> = {
  'hidden': {
    extraLarge: css`
      display: none;
    `,
    large: css`
      display: none;
    `,
    small: css`
      display: none;
    `,
  },

  'bottom left': {
    extraLarge: css`
      bottom: ${iconBadgeLargeTileVertGap};
      left: ${iconBadgeLargeTileSideGap};
    `,
    large: css`
      bottom: ${iconBadgeLargeTileVertGap};
      left: ${iconBadgeLargeTileSideGap};
    `,
    small: css`
      bottom: ${iconBadgeSmallTileVertGap};
      left: ${iconBadgeSmallTileSideGap};
    `,
  },

  'bottom right': {
    extraLarge: css`
      bottom: ${iconBadgeLargeTileVertGap};
      right: ${iconBadgeLargeTileSideGap};
    `,
    large: css`
      bottom: ${iconBadgeLargeTileVertGap};
      right: ${iconBadgeLargeTileSideGap};
    `,
    small: css`
      bottom: ${iconBadgeSmallTileVertGap};
      right: ${iconBadgeSmallTileSideGap};
    `,
  },

  'middle left': {
    extraLarge: css`
      left: ${iconBadgeLargeTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
    large: css`
      left: ${iconBadgeLargeTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
    small: css`
      left: ${iconBadgeSmallTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
  },

  'middle right': {
    extraLarge: css`
      right: ${iconBadgeLargeTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
    large: css`
      right: ${iconBadgeLargeTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
    small: css`
      right: ${iconBadgeSmallTileSideGap};
      top: 50%;
      transform: translateY(-50%);
    `,
  },

  'top left': {
    extraLarge: css`
      left: ${iconBadgeLargeTileSideGap};
      top: ${iconBadgeLargeTileVertGap};
    `,
    large: css`
      left: ${iconBadgeLargeTileSideGap};
      top: ${iconBadgeLargeTileVertGap};
    `,
    small: css`
      left: ${iconBadgeSmallTileSideGap};
      top: ${iconBadgeSmallTileVertGap};
    `,
  },

  'top right': {
    extraLarge: css`
      right: ${iconBadgeLargeTileSideGap};
      top: ${iconBadgeLargeTileVertGap};
    `,
    large: css`
      right: ${iconBadgeLargeTileSideGap};
      top: ${iconBadgeLargeTileVertGap};
    `,
    small: css`
      right: ${iconBadgeSmallTileSideGap};
      top: ${iconBadgeSmallTileVertGap};
    `,
  },
}

const getIconBodyWidth = (size: IconSize) => {
  switch (size) {
    case 'extraLarge':
      return {
        min: '75px',
        max: '85px',
      }
    case 'large':
      return {
        min: '60px',
        max: '80px',
      }
    default:
      return {
        min: '40px',
        max: '50px',
      }
  }
}

const getIconCaptionFontSize = (size: IconSize) => {
  switch (size) {
    case 'extraLarge':
      return '11px'
    case 'large':
      return '8px'
    default:
      return '5px'
  }
}

const getIconSizeStyles = (size: IconSize, iconSrc?: string) => {
  switch (size) {
    case 'extraLarge':
      return css`
        font-size: ${iconSrc ? '10px' : '30px'};
        height: 40px;
        line-height: 40px;
        width: 40px;
      `
    case 'large':
      return css`
        font-size: ${iconSrc ? '8px' : '24px'};
        height: 44px;
        line-height: 44px;
        width: 44px;
      `
    default:
      return css`
        font-size: ${iconSrc ? '5px' : '16px'};
        height: 32px;
        line-height: 32px;
        width: 32px;
      `
  }
}

const getCaptionColor = (defaultColor: string, captionColor?: string) => {
  return captionColor || defaultColor
}

export const Body = styled.figure<{
  size: IconSize
}>`
  font-weight: bold;
  line-height: 1.25;
  min-width: ${o => getIconBodyWidth(o.size).min};
  max-width: ${o => getIconBodyWidth(o.size).max};
`

export const Caption = styled.figcaption<{
  size: IconSize
  captionColor?: string
}>`
  font-size: ${o => getIconCaptionFontSize(o.size)};
  text-transform: uppercase;
  color: ${o => getCaptionColor(o.theme.color.deprecated.obsidian, o.captionColor)};
`

export const Icon = styled.div<{
  iconSrc?: string
  size: IconSize
}>`
  background: ${o => o.iconSrc && `center / contain no-repeat url("${o.iconSrc}")`};
  margin: 0 auto;
  white-space: nowrap;

  ${o => getIconSizeStyles(o.size, o.iconSrc)}

  + ${Caption} {
    margin-top: 3px;

    ${fromMd} {
      margin-top: 4px;
    }
  }
`
